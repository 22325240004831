body {
  padding: 0px;
  margin: 0px;
  background: linear-gradient(to right top, rgb(36, 35, 47), rgb(14, 163, 222));
}
.stack-container {
  backdrop-filter: blur(1rem);
}
.chainrents {
  position: absolute;
  left: 4%;
  color: white;
}
.description {
  /* background-color: red; */
  /* background: linear-gradient(to right top,cadetblue,lavenderblush); */
  margin-top: 5rem;
}

.icon-buttons {
  display: flex;
  justify-content: space-around;
  margin: 3rem;
}

.working {
  /* background-color: blue; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
